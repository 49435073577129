.Footer {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    color: white;
    font-weight: 500;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 50px;
}

@media (max-width: 768px) { 
    .Footer {
        padding: 0 20px;
    }
}

.Footer .Container {
    position: relative;
    z-index: 1;
}

.Footer p {
    display: inline-block;
}

.Footer .Social {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    user-select: none;
}

.Footer .Social svg {
    fill: #eee;
    margin-left: 30px;
    position: relative;
    top: 15px;
    width: 20px;
    user-select: none;
}

.Footer .Underlay {
    background-color: rgba(0, 0, 0, .5);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}