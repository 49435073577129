.Inference {
    background: #111111;
    color: white;
    padding: 50px 20px;
}

.Inference p {
    color: white;
}

.Inference .Flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Inference button {
    background: #1f1f1f;
    border: 2px solid #333;
    box-sizing: border-box;
    color: #ddd;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 20px;
    text-align: left;
    width: calc(25% - 5px);
}

@media (max-width: 1200px) { 
    .Inference button {
        width: calc(50% - 5px);
    }
}

@media (max-width: 768px) { 
    .Inference {
        display: none;
    }
}

.Inference button[data-selected="true"] {
    border-color: #76CABC;
}

.Inference button[data-selected="false"]:hover {
    border-color: #aaa;
}

.Inference button svg {
    margin-right: 15px;
    position: relative;
    top: 2px;
    width: 20px;
}

.Inference .Actions span {
    color: #444;
    border: 2px solid #333;
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 20px;
}

.Inference .Actions svg {
    fill: #333;
    margin-right: 15px;
    position: relative;
    top: 3px;
    width: 20px;
}

.Inference .Actions span[data-active="true"] {
    border-image: linear-gradient(to left, #76CABC, #897DBB) 1 100%;
    border-image-slice: 1;
    color: white;
}

.Inference .Actions span[data-active="true"] svg {
    fill: #897DBB;
}