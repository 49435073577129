.Pricing {
    background: #111;
    padding: 100px 0;
}

@media (max-width: 768px) { 
    .Pricing {
        padding: 20px 20px;
        padding-bottom: 30px;
    }
}

@media (min-width: 768px) and (max-width: 992px) { 
    .Pricing {
        padding: 25px 50px;
    }
}

.Pricing .Flex.Container {
    display: flex;
    padding: 0 50px;
}

@media (max-width: 992px) { 
    .Pricing .Flex.Container {
        flex-direction: column;
        padding: 0;
    }
}


.Pricing .Description {
    color: white;
    font-size: 25px;
    font-weight: 800;
    margin-right: 20px;
}

.Pricing .Description ul {
    list-style: none;
    padding: 0;
}

.Pricing .Description li {
    font-size: 18px;
    font-weight: 400;
    margin: 30px 0;
}

.Pricing .Description li small {
    color: #aaa;
    font-size: 16px;
    font-weight: 300;
}

.Pricing .EarlyAccess {
    border: 1px solid var(--primary-dark-color);
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 3px 0 #1d1d1d;
    color: #eee;
    padding: 50px;
    width: 50%;
}

.Pricing .EarlyAccess h1 {
    font-family: 'Montserrat';
    font-size: 40px;
    font-weight: 400;
    margin: 0 0 10px 0;
}

.Pricing .EarlyAccess .Info {
    font-size: 18px;
    margin: 10px 0;
    padding: 0;
}

.Pricing .EarlyAccess .Price {
    position: relative;
}

.Pricing .EarlyAccess .Price span {
    display: inline-block;
    text-align: left;
}

.Pricing .EarlyAccess .DollarSign {
    font-size: 20px;
    margin-right: 7px;
    top: 0;
}

.Pricing .EarlyAccess .Price .Amount {
    bottom: 20px;
    font-size: 70px;
    font-weight: 800;
    left: 20px;
    top: -15px;
}

.Pricing .EarlyAccess .Price .Description {
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    left: 200px;
    margin: 0;
    top: 10px;
}

.Pricing .EarlyAccess .Price .PSA {
    color: var(--secondary-color);
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    margin-top: 10px;
}

.Pricing .EarlyAccess .Price .WaitListButton {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    border: none;
    border-bottom: 3px solid var(--primary-dark-color);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    margin: 20px 0;
    padding: 12px 25px;
    text-decoration: none;
}

.Pricing .EarlyAccess .Price .WaitListButton:hover {
    background: var(--primary-color);
}


@media (max-width: 992px) { 
    .Pricing .EarlyAccess {
        padding: 30px;
        width: 100%;
    }

    .Pricing .EarlyAccess h1 {
        font-size: 25px;
    }

    .Pricing .EarlyAccess .Price .Amount {
        font-size: 50px;
    }

    .Pricing .EarlyAccess .Price .Description {
        font-size: 18px;
    }
}
