.Header {
    background: #111;
    overflow: hidden;
    position: relative;
}

.Header .Container {
    padding: 0 50px;
}

@media (max-width: 768px) { 
    .Header .Container {
        padding: 0 20px;
    }
}

.Header .Nav {
    padding: 100px 0 70px 0;
}

@media (max-width: 768px) { 
    .Header .Nav {
        padding: 30px 0;
    }
}

.Header .Brand {
    margin-right: 40px;
    position: relative;
    width: 150px;
    z-index: 1;
}

.Header .Nav .Links {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.Header .Nav .Links a {
    border-radius: 5px;
    color: #eee;
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px;
    padding: 10px 15px;
    position: relative;
    bottom: 3px;
    text-decoration: none;
}

.Header .Nav .Links a:visited {
    color: #eee;
}

.Header .Nav .Links a.EarlyAccess {
    border: 1px solid var(--primary-color);
}

.Header .Nav .Links a.EarlyAccess:hover {
    background: var(--primary-color);
}

@media (max-width: 768px) { 
    .Header .Nav .Links a.EarlyAccess {
        display: none;
    }
}

.Header .Intro {
    color: #eee;
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
}

.Header .Intro h1 {
    font-size: 76px;
    font-weight: 800;
    margin: 0;
}


.Header .Intro p {
    font-size: 30px;
    font-weight: 200;
    margin-top: 15px;
    max-width: 80%;
}

.Header .Intro a {
    background: var(--primary-color);
    border: none;
    border-bottom: 3px solid var(--primary-dark-color);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;
    padding: 12px 25px;
    text-decoration: none;
}

.Header .Intro a:hover {
    background-color: var(--primary-dark-color);
}

@media (max-width: 768px) {
    .Header .Intro {
        color: #eee;
        padding-bottom: 40px;
    }
    
    .Header .Intro h1 {
        font-size: 30px;
        font-weight: 800;
        margin: 0;
    }

    .Header .Intro p {
        font-size: 18px;
        font-weight: 300;
        margin-top: 15px;
    }
}
.Header .DotBG {
    background-color: var(--primary-dark-color);
    position: absolute;
    border-radius: 50%;
    z-index: 0
  }
  