body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-size: 16px;
  font-family: 'Source Code Pro';
}

:root {
  --page-max-width: 1440px;
  --primary-color: #897DBB;
  --primary-dark-color: #5e5099;
  --secondary-color: #76CABC;
  --secondary-dark-color: #42ad9b;
}

.Container {
  margin: auto;
  max-width: var(--page-max-width);
}