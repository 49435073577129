.Product .Bullets {
    border-top: 1px solid #222;
    background: #111;
}

.Product .Bullets .Container {
    display: flex;
    padding: 50px 20px;
    justify-content: space-between;
}

.Product .Bullets .Bullet {
    border: 2px solid;
    border-image: linear-gradient(to top, #76CABC, #897DBB) 1 100%;
    border-image-slice: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 22%;
}

.Product .Bullets .Bullet svg {
    fill: white;
    margin: 0 auto 20px auto;
    padding: 0;
    width: 40px;
}

.Product .Bullets .Bullet h2 {
    color: white;
    font-size: 20px;
    font-weight: 900;
    margin: 0;
    text-align: center;
}

.Product .Bullets .Bullet p {
    color: white;
    margin: 20px 0 0 0;
    text-align: center;
}

.Product .Bullets .Bullet a {
    color: #76CABC;
    font-weight: bold;
    text-decoration: none;
}

.Product .Commands {
    background: linear-gradient(90deg, #897DBB 0%, #76CABC 100%);
    padding: 100px 0;
}

.Product .Commands h1 {
    padding: 0 20px;
}

@media (max-width: 1200px) { 
    .Product .Bullets .Container {
        flex-direction: column;
    }  

    .Product .Bullets .Bullet {
        border-image: linear-gradient(to left, #76CABC, #897DBB) 1 100%;
        border-image-slice: 1;
        margin-bottom: 20px;
        width: 100%;
    }

    .Product .Bullets .Bullet:last-of-type {
        margin: 0;
    }
}

@media (max-width: 768px) { 
    .Product .Commands {
        padding: 40px 0 60px 0;
    }

    .Product h1 {
        font-size: 25px;
    }

    .Product .Bullets .Container {
        padding: 20px;
    } 

    .Product .Bullets .Bullet svg {
        width: 30px;
    }

    .Product .Bullets .Bullet h2 {
        font-size: 16px;
    }
    
    .Product .Bullets .Bullet p {
        font-size: 14px;
    }
}

.Product h1 {
    color: white;
    font-weight: 900;
    margin: 0 0 50px 0;
    text-align: center;
}

.Product .Tabs {
    width: 100%;
}

.Product .Tabs .Picker {
    border-radius: 10px;
    box-shadow: 0 5px 2px rgba(0, 0, 0, 0.1);
    margin: auto;
    text-align: center;
    max-width: 750px;
    display: flex;
    justify-content: space-evenly;
}

.Product .Tabs .Picker span {
    background: white;
    box-sizing: border-box;
    color: #555;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    padding: 15px;
    user-select: none;
    width: 33.3333333333333333333%;
}

.Product .Tabs .Picker span:hover {
    background-color: #f7f7f7;
}

.Product .Tabs .Picker span:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Product .Tabs .Picker span:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Product .Tabs .Picker span[data-selected="true"] {
    box-shadow: inset 0 -3px var(--secondary-color);
}

@media (max-width: 768px) { 
    .Product .Tabs .Picker {
        margin: 0 20px;
    }
}

.Product .Details {
    text-align: center;
}

.Product .Details p {
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: 60px auto;
}

.Product .Details code {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: white;
    font-family: 'Montserrat';
    font-weight: bold;
    padding: 15px 50px;
}

@media (max-width: 768px) { 
    .Product .Details code {
        font-size: 15px;
    }

    .Product .Details p {
        font-size: 16px;
        padding: 0 20px;
        margin: 60px auto;
    }
}
